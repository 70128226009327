.blog .container {
  padding-bottom: 40px;
}
.blog .blog-item {
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 30px;
}
.blog .blog-item-inner {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #d4d4e3;
}
.blog .blog-item-inner .blog-img {
  position: relative;
  overflow: hidden;
}
.blog .blog-item-inner .blog-img img {
  width: 100%;
  display: block;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog .blog-item-inner:hover .blog-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blog .blog-item-inner .blog-img .blog-data {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  padding: 4px 10px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  left: 15px;
  bottom: 15px;
}
.blog .blog-item-inner .blog-info {
  padding: 30px 15px;
  background: #fdf9ff;
}

.blog .blog-item-inner .blog-info .blog-title {
  font-size: 18px;
  font-weight: 700;
  color: #302e4d;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

.blog .blog-item-inner .blog-info .blog-descrip {
  font-size: 16px;
  line-height: 25px;
  color: #504e70;
  font-weight: 400;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

.blog .blog-item-inner .blog-info .blog-tags {
  font-size: 16px;
  line-height: 25px;
  color: #504e70;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
}

.blog .blog-item-inner .blog-info .blog-tags a {
  text-decoration: none;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .section .container {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-section .nav-tog {
    display: -ms-flexbox;
    display: flex;
    left: 30px;
  }
  .navbar-section .nav-tog.open {
    left: 300px;
  }
  .navbar-section {
    left: -270px;
  }
  .navbar-section.open {
    left: 0px;
  }
  .section {
    left: 0;
  }
  .section.open {
    left: 270px;
  }
  .about .about-content .personal .info-i p span {
    display: block;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .blog .blog-item,
  .portfolio .portfolio-l,
  .service .service-i {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .contact .contact-form .col-6,
  .blog .blog-item,
  .service .service-i,
  .about .about-content .exp,
  .about .about-content .edu,
  .about .about-content .skills,
  .about .about-content .personal {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .portfolio .portfolio-filter button {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .contact .contact-info-item,
  .portfolio .portfolio-l {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}
