.about .about-content {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  float: left;
}

.about .about-content .about-text {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-right: 15px;
}
.about .about-content .about-text h3 {
  text-align: left;
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #302e4d;
  font-family: "Montserrat", sans-serif;
}

.about .about-content .about-text p {
  text-align: left;
  font-size: 15px;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  color: #504e70;
  margin: 0;
}

.about .about-content .personal {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
}
.about .about-content .personal .info-i {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  font-size: 15px;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
}

.about .about-content .personal .info-i p {
  font-weight: 600;
  padding: 10px 0;
  font-size: 15px;
  color: #302e4d;
  border-bottom: 1px solid #e8dfec;
  margin: 0;
}
.about .about-content .personal .info-i p span {
  font-weight: 400;
  color: #504e70;
  margin-left: 4px;
  display: inline-block;
}
.about .about-content .personal .button {
  margin-top: 40px;
  padding-right: 5px;
}
.about .about-content .personal .button .btn {
  margin-right: 15px;
  margin-top: 10px;
}
.about .about-content .skills {
  /*background: blue;*/
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;

  margin-top: 40px;

  font-family: "Montserrat", sans-serif;
}

.about .about-content .skills .skill-i {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .skills .skill-i h5 {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #302e4d;
  transition: capitalize;
  margin: 0;
  text-align: left;
}

.about .about-content .skills .skill-i .pro {
  background: #e3e3e3;
  height: 7px;
  border-radius: 4px;

  width: 100%;
  position: relative;
}
.about .about-content .skills .skill-i {
  margin-bottom: 30px;
}
.about .about-content .skills .skill-i .pro .pro-i {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
}

.about .about-content .skills .skill-i .skill-p {
  line-height: 40px;
  position: absolute;
  right: 0;
  top: -40px;
  font-weight: 300;
  color: #302e4d;
  font-size: 15px;
}
.about .about-content .edu {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;

  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
}
.about .about-content .exp {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;

  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
}

.about .about-content h3.title {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #302e4d;
  text-align: left;
}
.about .about-content .timeline-box {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 1px;
}
.about .about-content .timeline {
  background: #fdf9ff;
  padding: 30px 15px;
  border: 1px solid #d4d4e3;
  border-radius: 10px;
  width: 100%;
  position: relative;
}
.about .about-content .timeline .timeline-item {
  position: relative;
  padding-left: 37px;
  padding-bottom: 50px;
  text-align: left;
}
.about .about-content .timeline .timeline-item:last-child {
  padding-bottom: 0px;
}
.about .about-content .timeline .timeline-item::before {
  content: "";
  width: 1px;
  position: absolute;
  height: 100%;
  left: 7px;
  top: 0;
}
.about .about-content .timeline .timeline-item-edu {
  position: relative;
  padding-left: 15px;

  text-align: left;
}
.about .about-content .timeline .timeline-item-edu:last-child {
  padding-bottom: 0px;
}
.about .about-content .timeline .timeline-item-edu::before {
  content: "";
  width: 1px;
  position: absolute;
  height: 100%;
  top: 0;
}
.about .about-content .timeline .circle-dot {
}
.timeline-logo {
  border-radius: 50%;
  margin-right: 40px; /* Space between logo and date */
  position: absolute;
  left: -8px;
  top: -3px;
  height: 30px;
  width: 30px;
  background-color: #fff; /* Optional: Add background color */
  border: 2px solid #ddd; /* Optional: Add border */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.about .about-content .timeline .timeline-date {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 12px;
  color: #504e70;
}
.about .about-content .timeline .timeline-date .fa {
  margin-right: 5px;
}
.about .about-content .timeline .timeline-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: #302e4d;
  text-align: left;
}
.about .about-content .timeline .timeline-text {
  font-size: 15px;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  color: #504e70;
  margin: 0;
}
@media (max-width: 1199px) {
  .section .container {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-section .nav-tog {
    display: -ms-flexbox;
    display: flex;
    left: 30px;
  }
  .navbar-section .nav-tog.open {
    left: 300px;
  }
  .navbar-section {
    left: -270px;
  }
  .navbar-section.open {
    left: 0px;
  }
  .section {
    left: 0;
  }
  .section.open {
    left: 270px;
  }
  .about .about-content .personal .info-i p span {
    display: block;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .blog .blog-item,
  .portfolio .portfolio-l,
  .service .service-i {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .contact .contact-form .col-6,
  .blog .blog-item,
  .service .service-i,
  .about .about-content .exp,
  .about .about-content .edu,
  .about .about-content .skills,
  .about .about-content .personal {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .portfolio .portfolio-filter button {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .contact .contact-info-item,
  .portfolio .portfolio-l {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}

.timeline-image {
  width: 90px; /* Adjust the width as needed */
  height: 90px; /* Maintain aspect ratio */
  margin-right: 15px; /* Space between image and text */
  border-radius: 7px;
  background: transparent; /* Ensure no background color is applied */
  object-fit: contain;
}
.timeline-item-content {
  display: flex;
  align-items: center;
}
.timeline-text-content {
  flex: 1; /* Allows the text content to take up remaining space */
}
