.service .container {
  padding-bottom: 40px;
}
.service .service-i {
  -ms-flex: 0 0 20.33%;
  flex: 0 0 20.33%;
  max-width: 20.33%;
  margin-bottom: 30px;
}

.service .service-i .service-item-inner {
  background: #fdf9ff;
  border: 1px solid #d4d4e3;
  border-radius: 10px;
  padding: 30px 15px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.service .service-i .service-item-inner:hover {
  -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}

.service .service-i .service-item-inner .icon {
  height: 60px;
  width: 60px;

  display: block;
  margin: 0 auto 30px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.service .service-i .service-item-inner .icon .fa {
  font-size: 40px;
  line-height: 60px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service .service-i .service-item-inner:hover .icon .fa {
  font-size: 25px;
  color: #ffffff;
}
.service .service-i .service-item-inner h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #302e4d;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
}
.service .service-i .service-item-inner p {
  font-size: 16px;
  color: #504e70;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
@media (max-width: 1199px) {
  .section .container {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-section .nav-tog {
    display: -ms-flexbox;
    display: flex;
    left: 30px;
  }
  .navbar-section .nav-tog.open {
    left: 300px;
  }
  .navbar-section {
    left: -270px;
  }
  .navbar-section.open {
    left: 0px;
  }
  .section {
    left: 0;
  }
  .section.open {
    left: 270px;
  }
  .about .about-content .personal .info-i p span {
    display: block;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .blog .blog-item,
  .portfolio .portfolio-l,
  .service .service-i {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .contact .contact-form .col-6,
  .blog .blog-item,
  .service .service-i,
  .about .about-content .exp,
  .about .about-content .edu,
  .about .about-content .skills,
  .about .about-content .personal {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .portfolio .portfolio-filter button {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .contact .contact-info-item,
  .portfolio .portfolio-l {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}

.tool {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: "center";
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ddd;
  overflow: "hidden";
}

.row-style {
  margin-left: 8px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
