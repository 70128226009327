.style-switcher {
  position: fixed;
  top: 20px;
  right: 0;
  z-index: 10;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #d4d4e3;
  border-right: none;
  border-radius: 0 0 0 5px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.style-switcher.open {
  transform: translateX(0%);
}

.style-switcher h5 {
  font-size: 16px;
  color: #302e4d;
  margin-top: 0;
  margin-bottom: 8px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e8dfec;
  font-family: "Montserrat", sans-serif;
}

.style-switcher ul {
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: 5px;
  list-style: none;
}

.style-switcher ul li {
  display: inline-block;
}

.style-switcher ul li a {
  height: 30px;
  width: 30px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none;
  margin-right: 2px;
}

.style-switcher .toggle-style-switcher {
  position: absolute;
  height: 45px;
  width: 45px;
  background: #ffffff;
  left: -46px;
  top: -1px;
  border: 1px solid #d4d4e3;
  border-right: none;
  text-align: center;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
}

.style-switcher .toggle-style-switcher .fa {
  font-size: 20px;
  line-height: 43px;
}

.switcher-light {
  font-size: 14px;
  padding-left: 8px;
  font-weight: 400;
}
