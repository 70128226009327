@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  direction: ltr;
}

body {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
}

input,
textarea,
select {
  font-family: "Montserrat", sans-serif;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

::before,
::after {
  box-sizing: border-box;
}

.section {
  /* background: #f2f2fc; */
  min-height: 100vh;
  display: block;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.section.back-section {
  z-index: 1;
}
.section.active {
  opacity: 1;
  z-index: 2;
  -webkit-animation: slideSection 1s ease;
  animation: slideSection 1s ease;
}
@-webkit-keyframes slideSection {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slideSection {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.section .container {
  padding-top: 60px;
  padding-bottom: 70px;
}
.section-t {
  -ms-flex: 0 0 0.1%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 60px;
  text-align: left;
}

.section-t h2 {
  font-size: 30px;
  color: #302e4d;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin: 0;
  position: relative;
}
.section-t-span h2 {
  font-size: 26px;
  color: #302e4d;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin: 0;
  position: relative;
}
.section-t-span {
  -ms-flex: 0 0 0.1%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 30px;
  text-align: left;
}

.section-t h2::before {
  content: "";
  height: 4px;
  width: 50px;
  position: absolute;
  top: 100%;
  left: 0;
}

.section-t h2::after {
  content: "";
  height: 4px;
  width: 25px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: warp;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}
.padd-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  max-width: 1100px;
  margin: auto;
  width: 100%;
}
.shadow-dark {
  -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
.btn {
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  padding: 12px 35px;
  color: #ffffff;
  border-radius: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.preloader {
  background: #222222;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 150;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.preloader.opacity-0 {
  opacity: 0;
}
.preloader .loader {
  height: 40px;
  width: 40px;
  border: 4px solid #e3e3e3;
  border-radius: 50%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  -webkit-animation: spin 2s liner infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lightboxImage {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lightboxImage {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* body.dark .section {
  background: #151515;
} */
body.dark .contact .contact-form .from-item .form-control,
body.dark .blog .blog-item-inner .blog-info,
body.dark .service .service-i .service-item-inner,
body.dark .about .about-content .timeline {
  background: #222222;
}
body.dark .contact .contact-info-item h4,
body.dark .blog .blog-item-inner .blog-info .blog-title,
body.dark .portfolio .portfolio-filter button,
body.dark .service .service-i .service-item-inner h4,
body.dark .about .about-content .timeline .timeline-title,
body.dark .about .about-content h3.title,
body.dark .about .about-content .skills .skill-i h5,
body.dark .about .about-content .personal .info-i p,
body.dark .about .about-content .about-text h3,
body.dark .section-t h2,
body.dark .home .intro h1,
body.dark .navbar-section .logo a,
body.dark .navbar-section .nav li a,
body.dark .section-t-span h2 {
  color: #ffffff;
}
body.dark .contact .contact-form .from-item .form-control,
body.dark .contact .contact-info-item p,
body.dark .blog .blog-item-inner .blog-info .blog-tags,
body.dark .blog .blog-item-inner .blog-info .blog-descrip,
body.dark .service .service-i .service-item-inner p,
body.dark .about .about-content .timeline .timeline-text,
body.dark .about .about-content .timeline .timeline-date,
body.dark .about .about-content .skills .skill-i .skill-p,
body.dark .about .about-content .personal .info-i p span,
body.dark .about .about-content .about-text p,
body.dark .home .intro p,
body.dark .section-t-span {
  color: #e9e9e9;
}
body.dark .navbar-section,
body.dark .contact .contact-form .from-item .form-control,
body.dark .blog .blog-item-inner,
body.dark .portfolio .portfolio-l-inner,
body.dark .service .service-i .service-item-inner,
body.dark .about .about-content .timeline,
body.dark .about .about-content .personal .info-i p,
body.dark .navbar-section .nav li a {
  border-color: #393939;
}

body.dark .shadow-dark {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 1199px) {
  .section .container {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-section .nav-tog {
    display: -ms-flexbox;
    display: flex;
    left: 30px;
  }
  .navbar-section .nav-tog.open {
    left: 300px;
  }
  .navbar-section {
    left: -270px;
  }
  .navbar-section.open {
    left: 0px;
  }
  .section {
    left: 0;
  }
  .section.open {
    left: 270px;
  }
  .about .about-content .personal .info-i p span {
    display: block;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .blog .blog-item,
  .portfolio .portfolio-l,
  .service .service-i {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .contact .contact-form .col-6,
  .blog .blog-item,
  .service .service-i,
  .about .about-content .exp,
  .about .about-content .edu,
  .about .about-content .skills,
  .about .about-content .personal {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }

  .portfolio .portfolio-filter button {
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .contact .contact-info-item,
  .portfolio .portfolio-l {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}
