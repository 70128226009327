.contact .contact-info-item {
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  text-align: center;
  margin-bottom: 40px;
  font-family: "Montserrat", sans-serif;
}

.contact .contact-info-item .icon {
  display: inline-block;
}
.contact .contact-info-item .icon .fa {
  font-size: 40px;
}
.contact .contact-info-item h4 {
  font-size: 18px;
  font-weight: 700;
  color: #302e4d;
  text-transform: capitalize;
  margin: 15px 0 5px;
}
.contact .contact-info-item p {
  font-size: 16px;
  line-height: 25px;
  color: #504e70;
  margin: 0;
  font-weight: 400;
}

.contact .contact-form {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.contact .contact-form .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.contact .contact-form .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.contact .contact-form .from-item {
  margin-bottom: 30px;
}
.contact .contact-form .from-item .form-control {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #d4d4e3;
  padding: 10px 25px;
  font-size: 16px;
  color: #504e70;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact .contact-form .from-item .form-control:focus {
  -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
.contact .contact-form .from-item textarea.form-control {
  height: 140px;
}

.contact .contact-form .btn {
  height: 50px;
  padding: 0 50px;
}
@media (max-width: 575px) {
  .contact .contact-info-item,
  .portfolio .portfolio-l {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}
